import store from "../store"
export default async function auth({ next, router }) {

    const userPermissions = store.getters['admin/Abilities'].permissions;
    const body = document.body.classList

    try {
        if (!userPermissions) {
            // const healtcheck = await store.dispatch('admin/Healt-Check')
            const ability = await store.dispatch('admin/Abilities')
            const access = await store.dispatch('admin/Access')
        }

        body.remove('login-page')
        return next();
    } catch (error) {
        if (error.response.status === 401) {
            await store.dispatch('admin/deleteSessions')
            return router.push({ name: 'login' });
        }
    }

}