import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

//middleware
import Middlewares from '../middlewares'
//end

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    meta: {
      middleware: [Middlewares.Guest]
    },

    component: () => import(/* webpackChunkName: "home" */ '../views/Login.vue')
  },
  {
    path: '/',
    name: 'home',
    meta: {
      middleware: [Middlewares.Auth],
      breadcrumb: {
        label: 'Home',
        name: 'home'
      }
    },

    component: () => import(/* webpackChunkName: "home" */ '../views/dashboard.vue')
  },
  {
    path: '/user',
    name: 'user-control',
    meta: {
      middleware: [Middlewares.Auth],
      breadcrumb: {
        label: 'User',
        name: 'user-control'
      }
    },

    component: () => import(/* webpackChunkName: "home" */ '../views/User.vue')
  },
  {
    path: '/add-user',
    name: 'user-register',
    meta: {
      middleware: [Middlewares.Auth],
      breadcrumb: {
        label: 'User Register',
        parent: 'user-control'
      }
    },

    component: () => import(/* webpackChunkName: "home" */ '../views/Tambah/RegisterAkun.vue')
  },

  {
    path: '/user/:id?',
    name: 'edit-user',
    props: true,
    meta: {
      middleware: [Middlewares.Auth],
      breadcrumb: {
        label: 'Edit User',
        parent: 'user-control'
      }
    },

    component: () => import(/* webpackChunkName: "home" */ '../views/Edit/EditAkun.vue')
  },
  {
    path: '/kelas',
    name: 'kelas-control',
    meta: {
      middleware: [Middlewares.Auth],
      breadcrumb: {
        label: 'Kelas',
        name: 'kelas-control'
      }
    },

    component: () => import(/* webpackChunkName: "home" */ '../views/Kelas.vue')
  },
  {
    path: '/add-kelas',
    name: 'add-kelas',
    meta: {
      middleware: [Middlewares.Auth],
      breadcrumb: {
        label: 'Tambah Kelas',
        parent: 'kelas-control'
      }
    },

    component: () => import(/* webpackChunkName: "home" */ '../views/Tambah/Kelas.vue')
  },
  {
    path: '/kelas/:id?',
    name: 'edit-kelas',
    props: true,
    meta: {
      middleware: [Middlewares.Auth],
      breadcrumb: {
        label: 'Edit Kelas',
        parent: 'kelas-control'
      }
    },

    component: () => import(/* webpackChunkName: "home" */ '../views/Edit/Kelas.vue')
  },
  {
    path: '/matapelajaran',
    name: 'matapelajaran-control',
    meta: {
      middleware: [Middlewares.Auth],
      breadcrumb: {
        label: 'Mata Pelajaran',
        name: 'matapelajaran-control'
      }
    },

    component: () => import(/* webpackChunkName: "home" */ '../views/MataPelajaran.vue')
  },
  {
    path: '/add-matapelajaran',
    name: 'add-matapelajaran',
    meta: {
      middleware: [Middlewares.Auth],
      breadcrumb: {
        label: 'Tambah Pelajaran',
        parent: 'matapelajaran-control'
      }
    },

    component: () => import(/* webpackChunkName: "home" */ '../views/Tambah/MataPelajaran.vue')
  },
  {
    path: '/matapelajaran/:id?',
    name: 'edit-matapelajaran',
    props: true,
    meta: {
      middleware: [Middlewares.Auth],
      breadcrumb: {
        label: 'Edit Pelajaran',
        parent: 'matapelajaran-control'
      }
    },

    component: () => import(/* webpackChunkName: "home" */ '../views/Edit/MataPelajaran.vue')
  },
  {
    path: '/verified-user',
    name: 'verified',
    props: true,
    meta: {
      middleware: [Middlewares.Auth],
      breadcrumb: {
        label: 'Verifeid',
        name: 'verified'
      }
    },

    component: () => import(/* webpackChunkName: "home" */ '../views/verified.vue')
  },
  {
    path: '/formulir',
    name: 'formulir',
    props: true,
    meta: {
      middleware: [Middlewares.Auth],
      breadcrumb: {
        label: 'Formulir',
        name: 'formulir'
      }
    },

    component: () => import(/* webpackChunkName: "home" */ '../views/formulir.vue')
  },
  {
    path: '/export/users',
    name: 'users-export',
    props: true,
    meta: {
      middleware: [Middlewares.Auth],
      breadcrumb: {
        label: 'User Export',
        name: 'kelas-export'
      }
    },

    component: () => import(/* webpackChunkName: "kelas" */ '../views/Export/User/user.vue')
  },
  {
    path: '/export/matpel',
    name: 'matpel-export',
    props: true,
    meta: {
      middleware: [Middlewares.Auth],
      breadcrumb: {
        label: 'Mata Pelajaran Export',
        name: 'matpel-export'
      }
    },

    component: () => import(/* webpackChunkName: "kelas" */ '../views/Export/Mata_Pelajaran/matpel.vue')
  },
  {
    path: '/export/kelas',
    name: 'kelas-export',
    props: true,
    meta: {
      middleware: [Middlewares.Auth],
      breadcrumb: {
        label: 'Kelas Export',
        name: 'kelas-export'
      }
    },

    component: () => import(/* webpackChunkName: "kelas" */ '../views/Export/Kelas/Kelas.vue')
  },
  {
    path: '/export/kelas/:title?/participant',
    name: 'participant-kelas',
    props: true,
    meta: {
      middleware: [Middlewares.Auth],
      breadcrumb: {
        label: 'Kelas Participant',
        parent: 'kelas-export'
      }
    },

    component: () => import(/* webpackChunkName: "participant" */ '../views/Export/Kelas/participant.vue')
  },
  {
    path: '/export/kelas/:title?/nilai',
    name: 'participant-nilai',
    props: true,
    meta: {
      middleware: [Middlewares.Auth],
      breadcrumb: {
        label: 'Kelas Nilai',
        parent: 'kelas-export'
      }
    },

    component: () => import(/* webpackChunkName: "nilai" */ '../views/Export/Kelas/nilai.vue')
  },
  {
    path: '/kelas-export/:title?/absen',
    name: 'participant-absen',
    props: true,
    meta: {
      middleware: [Middlewares.Auth],
      breadcrumb: {
        label: 'Kelas Absen',
        parent: 'kelas-export'
      }
    },

    component: () => import(/* webpackChunkName: "nilai" */ '../views/Export/Kelas/absen.vue')
  },

  {
    path: '/export/formulir',
    name: 'formulir-export',
    props: true,
    meta: {
      middleware: [Middlewares.Auth],
      breadcrumb: {
        label: 'Formulir Export',
        name: 'formulir-export'
      }
    },

    component: () => import(/* webpackChunkName: "kelas" */ '../views/Export/Formulir/formulir.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  linkExactActiveClass: "active"
})

function recursiveNext(context, middleware, index) {

  const nextMiddleware = middleware[index];

  if (!nextMiddleware) return context.next;

  return (...parameters) => {
    context.next(...parameters); //hooks

    const next = recursiveNext(context, middleware, index + 1);

    nextMiddleware({ ...context, next: next });
  }
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {

    const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to
    }

    const nextMiddleware = recursiveNext({ from: from, next: next, router: router, to: to }, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  }

  return next
})

export default router
