<template>
  <aside class="main-sidebar">
    <!-- sidebar: style can be found in sidebar.less -->
    <section class="sidebar">
      <!-- Sidebar user panel -->
      <div class="user-panel mb-5">
        <div class="pull-left image">
          <img
            src="https://cdn.vuetifyjs.com/images/lists/1.jpg"
            class="img-circle"
            alt="User Image"
          />
        </div>
        <div class="pull-left info" v-if="information">
          <p>{{ information.nama }}</p>
          <a href="#"><i class="fa fa-circle text-success"></i> Online</a>
        </div>
        <div class="pull-left info" v-else>
          <p>loading...</p>
          <a href="#"><i class="fa fa-circle text-success"></i> Online</a>
        </div>
      </div>
      <!-- sidebar menu: : style can be found in sidebar.less -->
      <ul class="sidebar-menu" data-widget="tree">
        <li class="header">MAIN NAVIGATION</li>
        <li>
          <router-link to="/"
            ><i class="fa fa-book"></i> <span>Dashboard</span>
          </router-link>
        </li>
        <li class="treeview">
          <a href="#">
            <i class="fa fa-dashboard"></i> <span>User</span>
            <span class="pull-right-container">
              <i class="fa fa-angle-left pull-right"></i>
            </span>
          </a>
          <ul class="treeview-menu">
            <li>
              <router-link to="/user"
                ><i class="fa fa-circle-o"></i><span> User</span>
              </router-link>
            </li>
            <li>
              <router-link to="/user"
                ><i class="fa fa-circle-o"></i> <span>Role & Permissions</span>
              </router-link>
            </li>
          </ul>
        </li>
        <li class="header">AKADEMIK</li>
        <li>
          <router-link to="/kelas">
            <i class="fa fa-book"></i> <span>Kelas</span>
          </router-link>
        </li>
        <li>
          <router-link to="/matapelajaran">
            <i class="fa fa-book"></i> <span>Mata Pelajaran</span>
          </router-link>
        </li>
        <li class="treeview">
          <a href="">
            <i class="fa fa-share"></i> <span>Report</span>
            <span class="pull-right-container">
              <i class="fa fa-angle-left pull-right"></i>
            </span>
          </a>
          <ul class="treeview-menu">
            <li>
              <router-link to="/export/kelas">
                <i class="fa fa-book"></i> <span>Report Kelas</span>
              </router-link>
            </li>
          </ul>
        </li>

        <!-- <li class="header">Bussiness</li>
        <li>
          <a href="https://adminlte.io/docs"
            ><i class="fa fa-book"></i> <span>Pembayaran</span></a
          >
        </li> -->

        <li class="header">Portal</li>
        <li>
          <router-link to="/verified-user">
            <i class="fa fa-book"></i> <span>Konfirmasi Akun</span>
          </router-link>
        </li>

        <li>
          <router-link to="/formulir">
            <i class="fa fa-book"></i> <span>Formulir</span>
          </router-link>
        </li>

        <!-- <li>
          <a href="#"
            ><i class="fa fa-book"></i> <span>Pembayaran</span>
            <span class="pull-right-container">
              <span class="label label-success pull-right">8</span>
            </span>
          </a>
        </li> -->
      </ul>
    </section>
    <!-- /.sidebar -->
  </aside>
</template>

<script>
export default {
  props: ["information"],
}
</script>
