import axios from "axios";

// baseURL: 'http://127.0.0.1:8000/api/v1/',
// baseURL: 'https://api.behaustcode.my.id/api/v1/',
// baseURL: 'https://lms-api.rsudbandungkiwari.or.id/api/v1/',

const ax = axios.create({
  baseURL: 'https://lms-api.rsudbandungkiwari.or.id/api/v1/',
});

ax.interceptors.request.use(function (config) {
  // Do something before request is sent
  const token = localStorage.getItem('access_token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

export default ax