import axios from "../../../helpers/axios.js";
import store from "../..";

export default {
    /*
    *------------------------------
    * Proses Indetifikasi Ability
    * -----------------------------
    * Pada function ini terdapat Promise
    * request langsung terhadap endpoint API
    * yang telah dibuat.
    * 
    */

    Abilities: async (context) => {
        const ability = await axios({
            method: 'GET',
            url: 'abilities',
        })

        context.commit('SetAbilities', ability.data.datas);

        return ability
    },

    Access: async (context, payload) => {
        const access = await axios({
            method: 'GET',
            url: 'access'
        })

        context.commit('SetAccess', access.data.datas)
        return access.data.data
    },

    /*
    *------------------------------
    * Proses Auth
    * -----------------------------
    * Pada function ini terdapat Promise
    * request langsung terhadap endpoint API
    * yang telah dibuat.
    * 
    */

    handleRegister: async (context, payload) => {
        const register = await axios({
            method: 'POST',
            url: 'register',
            data: payload
        })

        return register
    },
    handleLogin: async (context, payload) => {
        const login = await axios({
            method: 'POST',
            url: 'login',
            data: payload
        })

        localStorage.setItem('access_token', login.data.access_token)
        await store.dispatch('admin/Abilities')
        await store.dispatch('admin/Access')

        return login
    },
    handleLogout: async (context) => {
        await axios({
            method: 'GET',
            url: 'logout',
        })

        await store.dispatch('admin/deleteSessions')
    },
    deleteSessions: async (context, payload) => {
        if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token');
            context.commit('SetAbilities', { information: { email: '', nama: '', roles: '' } });
            context.commit('SetLoggedIn', false);
            return true
        } else {
            context.commit('SetLoggedIn', false);
            return false
        }
    },
    setLoggedInState: async (context) => {
        if (localStorage.getItem('access_token')) {
            context.commit('SetLoggedIn', true);
            return true
        } else {
            context.commit('SetLoggedIn', false);
            return false
        }
    },

    /*
    *------------------------------
    * Proses User
    * -----------------------------
    * Pada function ini terdapat Promise
    * request langsung terhadap endpoint API
    * yang telah dibuat.
    * 
    */

    getUser: async (context, payload) => {
        const getUsers = await axios({
            method: 'GET',
            url: '/admin/users?page=' + payload.page +
                '&paginate=' + payload.paginate +
                '&q=' + payload.search +
                '&tipe=' + payload.tipeLevel +
                '&sortDirection=' + payload.sortDirection +
                '&sortField=' + payload.sortField,
        })

        return getUsers.data
    },
    deleteSingleUser: async (context, payload) => {
        const deleteSingle = await axios({
            method: 'DELETE',
            url: `admin/users/${payload}`
        })

        return deleteSingle.data
    },
    deleteRecordsUsers: async (context, payload) => {
        const deleteRecords = await axios({
            method: 'DELETE',
            url: `admin/users/${payload}/massDestroy`
        })

        return deleteRecords.data
    },
    addUser: async (context, payload) => {
        const tambahUser = await axios({
            method: 'POST',
            url: 'admin/users',
            data: payload
        })

        return tambahUser.data
    },
    getProfile: async (context, payload) => {
        const getProfile = await axios({
            method: 'GET',
            url: `admin/users/${payload}`
        })

        return getProfile.data
    },
    updateUser: async (context, payload) => {
        const updateUser = await axios({
            method: 'PUT',
            url: `admin/users/${payload.id}`,
            data: payload.profile
        })

        return updateUser.data
    },

    getUnVerifUser: async (context, payload) => {
        const getUnVerifUser = await axios({
            method: 'GET',
            url: '/admin/getUnVerifUser?page=' + payload.page +
                '&paginate=' + payload.paginate +
                '&q=' + payload.search +
                '&sortDirection=' + payload.sortDirection +
                '&sortField=' + payload.sortField,
        })

        return getUnVerifUser.data
    },

    getUserUnVerifiedProfile: async (context, payload) => {
        const getProfile = await axios({
            method: 'GET',
            url: `admin/users-unverif/${payload}`
        })

        return getProfile.data
    },

    verifiedUser: async (context, payload) => {
        const verifiedUser = await axios({
            method: 'POST',
            url: `admin/users-unverif/${payload.id}/verif?id=${payload.id}&start=${payload.start}&end=${payload.end}`,
        })

        return verifiedUser.data
    },
    /*
    *------------------------------
    * Proses Mata Pelajaran
    * -----------------------------
    * Pada function ini terdapat Promise
    * request langsung terhadap endpoint API
    * yang telah dibuat.
    * 
    */
    getMataPelajaran: async (context, payload) => {
        const getMataPelajaran = await axios({
            method: 'GET',
            url: 'admin/matapelajaran?page=' + payload.page +
                '&paginate=' + payload.paginate +
                '&q=' + payload.search +
                '&sortDirection=' + payload.sortDirection +
                '&sortField=' + payload.sortField
        })

        return getMataPelajaran.data
    },
    deleteSingleMataPelajaran: async (context, payload) => {
        const deleteSingle = await axios({
            method: 'DELETE',
            url: `admin/matapelajaran/${payload}`
        })

        return deleteSingle.data
    },
    deleteRecordsMataPelajaran: async (context, payload) => {
        const deleteRecords = await axios({
            method: 'DELETE',
            url: `admin/matapelajaran/${payload}/massDestroy`
        })

        return deleteRecords.data
    },
    addMataPelajaran: async (context, payload) => {
        const tambahMataPelajaran = await axios({
            method: 'POST',
            url: 'admin/matapelajaran',
            data: payload
        })

        return tambahMataPelajaran.data
    },
    getProfileMataPelajaran: async (context, payload) => {
        const getProfile = await axios({
            method: 'GET',
            url: `admin/matapelajaran/${payload}`
        })

        return getProfile.data
    },
    updateMataPelajaran: async (context, payload) => {
        const updateMataPelajaran = await axios({
            method: 'PUT',
            url: `admin/matapelajaran/${payload.id}`,
            data: payload.profile
        })

        return updateMataPelajaran.data
    },

    /*
    *------------------------------
    * Proses Kelas
    * -----------------------------
    * Pada function ini terdapat Promise
    * request langsung terhadap endpoint API
    * yang telah dibuat.
    * 
    */
    getKelas: async (context, payload) => {
        const getKelas = await axios({
            method: 'GET',
            url: 'admin/kelas?page=' + payload.page +
                '&paginate=' + payload.paginate +
                '&q=' + payload.search +
                '&sortDirection=' + payload.sortDirection +
                '&sortField=' + payload.sortField
        })

        return getKelas.data

    },
    kelasExtensions: async (context, payload) => {
        const extensions = await axios({
            method: 'GET',
            url: `admin/kelasExtensions`
        })

        return extensions.data
    },
    deleteSingleKelas: async (context, payload) => {
        const deleteSingle = await axios({
            method: 'DELETE',
            url: `admin/kelas/${payload}`
        })

        return deleteSingle.data
    },
    async deleteRecordsKelas(context, payload) {
        const deleteRecords = await axios({
            method: 'DELETE',
            url: `admin/kelas/${payload}/massDestroy`
        })

        return deleteRecords.data
    },
    addKelas: async (context, payload) => {
        const tambahKelas = await axios({
            method: 'POST',
            url: 'admin/kelas',
            data: payload
        })

        return tambahKelas.data
    },
    KelasMataPelajaran: async (context, payload) => {
        const kelasMataPelajaran = await axios({
            method: 'GET',
            url: 'admin/matapelajaran'
        })

        return kelasMataPelajaran.data
    },
    KelasPembimbing: async (context, payload) => {
        const KelasPembimbing = await axios({
            method: 'GET',
            url: 'admin/getPembimbing'
        })

        return KelasPembimbing.data
    },
    getProfileKelas: async (context, payload) => {
        const getProfile = await axios({
            method: 'GET',
            url: `admin/kelas/${payload}`
        })

        return getProfile.data
    },
    updateKelas: async (context, payload) => {
        const updateKelas = await axios({
            method: 'PUT',
            url: `admin/kelas/${payload.id}`,
            data: payload.profile
        })

        return updateKelas.data
    },

    getProfileKelas: async (context, payload) => {
        const getProfile = await axios({
            method: 'GET',
            url: `admin/kelas/${payload}`
        })

        return getProfile.data
    },

    //formulir

    getFormulir: async (context, payload) => {
        const formulir = await axios({
            method: 'GET',
            url: '/admin/portal/formulir?page=' + payload.page +
                '&paginate=' + payload.paginate +
                '&q=' + payload.search +
                '&t=' + payload.jenis_surat +
                '&m=' + payload.mark +
                '&s=' + payload.status +
                '&sortDirection=' + payload.sortDirection +
                '&sortField=' + payload.sortField,
        })

        return formulir.data
    },

    markFormulir: async (context, payload) => {
        const formulir = await axios({
            method: 'PUT',
            url: '/admin/portal/formulir/' + payload.id + '/mark',
            data: payload
        })

        return formulir.data
    },

    updateStatusFormulir: async (context, payload) => {
        const formulir = await axios({
            method: 'POST',
            url: '/admin/portal/formulir/' + payload.id + '/update-status',
            data: payload
        })

        return formulir.data
    },

    deleteFormulir: async (context, payload) => {
        const formulir = await axios({
            method: 'DELETE',
            url: '/admin/portal/formulir/' + payload.id + '/massDestroy',
            data: payload
        })

        return formulir.data
    },

    //kelas

    getParticipant: async (context, payload) => {
        const formulir = await axios({
            method: 'GET',
            url: '/admin/kelas/' + payload.title + '/participant'
        })

        return formulir.data
    },

    getNilai: async (context, payload) => {
        const formulir = await axios({
            method: 'GET',
            url: '/admin/kelas/' + payload.title + '/nilai'
        })

        return formulir.data
    },

    getAbsen: async (context, payload) => {
        const formulir = await axios({
            method: 'GET',
            url: '/admin/kelas/' + payload.title + '/absen'
        })

        return formulir.data
    },

    //export

    exportUser: async (context, payload) => {
        const formulir = await axios({
            method: 'GET',
            url: '/admin/export/users'
        })

        return formulir.data
    },

    exportMataPelajaran: async (context, payload) => {
        const formulir = await axios({
            method: 'GET',
            url: '/admin/export/mata_pelajaran'
        })

        return formulir.data
    },

    exportKelas: async (context, payload) => {
        const formulir = await axios({
            method: 'GET',
            url: '/admin/export/kelas'
        })

        return formulir.data
    },

    exportKelasParticipant: async (context, payload) => {
        const formulir = await axios({
            method: 'GET',
            url: '/admin/export/kelas/' + payload.title + '/participant'
        })

        return formulir.data
    },

    exportKelasNilai: async (context, payload) => {
        const formulir = await axios({
            method: 'GET',
            url: '/admin/export/kelas/' + payload.title + '/nilai'
        })

        return formulir.data
    },

    exportKelasAbsen: async (context, payload) => {
        const formulir = await axios({
            method: 'GET',
            url: '/admin/export/kelas/' + payload.title + '/absen'
        })

        return formulir.data
    },
}