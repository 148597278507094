export default {
    SetLoggedIn(state, payload) {
        state.isLoggedIn = payload;
    },
    SetAbilities(state, payload) {
        const data = {
            information: {
                email: payload.information.email,
                nama: payload.information.nama,
                roles: payload.information.roles.charAt(0).toUpperCase() + payload.information.roles.slice(1),
            },
            permissions: payload.permissions
        }
        state.abilities = data;
    },
    SetAccess(state, payload) {
        state.access = payload;
    },
}